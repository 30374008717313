import axios from '@/axios'
import { setAuthTokens, clearAuthTokens } from 'axios-jwt'

export default {
  login (data) {
    return new Promise((resolve, reject) => {
      axios
        .post('token/jwt/create/', data)
        .then(response => {
          setAuthTokens({
            accessToken: response.data.access,
            refreshToken: response.data.refresh
          })
          location.reload()
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  logout () {
    return new Promise((resolve) => {
      clearAuthTokens()
      localStorage.clear()
      resolve()
    })
  },
  getAllData (dateTime) {
    let url = 'crm/all-data/'
    if (dateTime) url = `crm/all-data/?datetime=${dateTime}`
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  patchBooking (data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`booking/${data.id}/`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  postReservationForm (data) {
    return new Promise((resolve, reject) => {
      axios
        .post('booking/new/', data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  getBooking (id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`booking/${id}/`)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  patchReservationForm (data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`booking/${data.id}/`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  search (data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`crm/search-book/?query=${data}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  getTables () {
    return new Promise((resolve, reject) => {
      axios
        .get('table/?page_size=200')
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  getStat () {
    return new Promise((resolve, reject) => {
      axios
        .get('crm/stat/')
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  getStatPay () {
    return new Promise((resolve, reject) => {
      axios
        .get('crm/pay-stat/')
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  getStatGuest (date) {
    return new Promise((resolve, reject) => {
      axios
        .get(`crm/guest-stat/?date=${date}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  reSendSMS (id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`booking/${id}/send-sms/`)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  getKeeperInfo (id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`booking/${id}/rk/`)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  postKeeperBook (id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`booking/${id}/rk/book/`)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  postKeeperPrepay (id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`booking/${id}/rk/prepay/`)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  exportStat () {
    return new Promise((resolve, reject) => {
      axios
        .get('crm/export-stat/', { responseType: 'blob' })
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  addToCalendar (id) {
    return axios.get(`booking/${id}/add-to-calendar/`)
  },
  removeFromCalendar (id) {
    return axios.get(`booking/${id}/delete-from-calendar/`)
  }
}
