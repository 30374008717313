import axios from 'axios'
import { applyAuthTokenInterceptor, clearAuthTokens } from 'axios-jwt'

// const baseURL = process.env.VUE_APP_API_URL
// const token = localStorage.getItem('accessToken')
//
// if (token) {
//   axios.defaults.headers.common.Authorization = `Bearer ${token}`
// }
//
// export default axios.create({
//   baseURL
// })

const baseURL = process.env.VUE_APP_API_URL
const axiosInstance = axios.create({ baseURL })

const requestRefresh = (refresh) => {
  return new Promise((resolve, reject) => {
    axios.post(`${baseURL}token/jwt/refresh/`, { refresh })
      .then(response => {
        resolve({
          accessToken: response.data.access,
          refreshToken: response.data.refresh
        })
      })
      .catch(error => {
        if (error.response.status === 401) {
          clearAuthTokens()
          localStorage.clear()
          // router.push({ name: 'login' }).catch(() => {})
        }
        reject(error)
      })
  })
}

axiosInstance.interceptors.response.use(undefined, function (err) {
  return new Promise(function () {
    if (err.response.status === 401) {
      clearAuthTokens()
      localStorage.clear()
      // router.push({ name: 'login' }).catch(() => {})
    }
    throw err
  })
})

applyAuthTokenInterceptor(axiosInstance, { requestRefresh })

export default axiosInstance
