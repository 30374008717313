<template>
  <div class="booking">
    <aside class="booking__sidebar">
      <div class="sidebar">
        <div class="sidebar__top">
<!--          <div v-if="!selected_table" class="sidebar__tabs">-->
<!--            <div class="sidebar__tab">-->
<!--              <input-->
<!--                id="active"-->
<!--                type="radio"-->
<!--                value="active"-->
<!--                name="reservation"-->
<!--                v-model="reservation_filter"-->
<!--                checked-->
<!--              >-->
<!--              <label for="active">Активные</label>-->
<!--            </div>-->
<!--            <div class="sidebar__separator">/</div>-->
<!--            <div class="sidebar__tab">-->
<!--              <input-->
<!--                id="history"-->
<!--                type="radio"-->
<!--                value="history"-->
<!--                name="reservation"-->
<!--                v-model="reservation_filter"-->
<!--              >-->
<!--              <label for="history">Закрытые</label>-->
<!--            </div>-->
<!--          </div>-->
          <div v-if="selected_table || selected_booking" class="sidebar__info">
            <div class="sidebar__info-main">СТОЛ №{{ selected_table_data.number }}</div>
            <div class="sidebar__info-sub">
              {{ selected_table_data.hall === 1 ? 'Каминный зал' : selected_table_data.hall === 3 ? 'Веранда' : 'Chef’s table' }}
            </div>
            <button @click="onRemoveTable" type="button" class="sidebar__info-close">
              <IconClose/>
            </button>
          </div>
          <div v-else class="sidebar__info">
            <div class="sidebar__info-main">Книга резервов</div>
          </div>
        </div>
        <div class="sidebar__content">
          <div v-if="!selected_table && !tables.all_bookings.length" class="sidebar__content-empty">
            Пока нет новых заявок на бронирование
          </div>
          <div v-show="!selected_table && !selected_booking && tables.all_bookings.length" class="sidebar__cards">
            <div v-if="!searchBookingsQuery.length" class="reservation-card">
              <div v-if="!filteredBookings.length" class="reservation-card__empty">Нет заявок</div>
              <button
                v-for="booking in filteredBookings"
                :key="booking.id"
                @click="onReservationCardListClick(booking.id, booking)"
                type="button"
                class="reservation-card__item"
                :class="{
                  'reservation-card__item--wait': booking.status === 'wait',
                  'reservation-card__item--open': booking.status === 'open'
                }"
              >
                <div v-if="!booking.is_read" class="reservation-card__new">Новая заявка</div>
                <div class="reservation-card__top">
                  <div>
                    <b v-if="booking.client.name">{{ booking.client.name }},</b>
                    <span> {{ booking.count_guests }} чел.</span>
                  </div>
                  <div class="reservation-card__time">{{ booking.short_time_from }}
                    - {{ booking.short_time_to }}
                  </div>
                </div>
                <div class="reservation-card__row">{{ booking.client.phone }}</div>
                <div class="reservation-card__row">
                  <span class="reservation-card__theme">Стол №{{ booking.table_number }}</span>
                  <span>. {{ (booking.table_number >= 11 && booking.table_number &lt;= 22) ? 'Каминный зал' : (booking.table_number >= 31 && booking.table_number &lt;= 64) ? 'Веранда' : 'Chef’s table' }}</span>
                </div>
                <div v-if="booking.invoice" class="reservation-card__between">
                  <div class="reservation-card__row">
                    Депозит: <span class="reservation-card__theme">{{ booking.invoice ? booking.invoice.amount : 0 }}₽</span>
                  </div>
                  <div class="reservation-card__row">
                    Внесено: <span class="reservation-card__theme">{{ booking.invoice.is_paid ? booking.invoice.amount : 0 }}₽</span>
                  </div>
                </div>
                <div class="reservation-card__row">{{ booking.comment }}</div>
                <div v-if="booking.status === 'wait' || booking.status === 'open'" class="reservation-card__update">
                  <span v-if="booking.status === 'wait'">Ожидание</span>
                  <span v-if="booking.status === 'open'">До закрытия</span>
                  <span> {{ booking.booking_time }}</span>
                </div>
              </button>
            </div>
            <div v-else class="reservation-card" :class="{ 'reservation-card--empty': searchedBookings.length < 1 }">
              <div v-if="searchedBookings.length < 1" class="reservation-card__empty reservation-card__empty--search">
                <CarbonSearch />
                По результатам вашего<br> запроса ничего не найдено
              </div>
              <div v-for="item in searchedBookings" :key="item.date">
                <div class="reservation-card__date">{{ item.date }}</div>
                <button
                  v-for="booking in item.bookings"
                  :key="booking.id"
                  @click="onReservationCardListClick(booking.id, booking)"
                  type="button"
                  class="reservation-card__item"
                  :class="{
                  'reservation-card__item--wait': booking.status === 'wait',
                  'reservation-card__item--open': booking.status === 'open'
                }"
                >
                  <div v-if="!booking.is_read" class="reservation-card__new">Новая заявка</div>
                  <div class="reservation-card__top">
                    <div class="reservation-card__number">Бронь №{{ booking.id }}</div>
                    <div class="reservation-card__time">{{ booking.short_time_from }}
                      - {{ booking.short_time_to }}
                    </div>
                  </div>
                  <div class="reservation-card__row">
                    <span class="reservation-card__theme">Стол №{{ booking.table_number }}</span>
                    <span>. {{ (booking.table_number >= 11 && booking.table_number &lt;= 22) ? 'Каминный зал' : (booking.table_number >= 31 && booking.table_number &lt;= 64) ? 'Веранда' : 'Chef’s table' }}</span>
                  </div>
                  <div class="reservation-card__row">
                    <b v-if="booking.client.name">{{ booking.client.name }},</b>
                    <span> {{ booking.count_guests }} чел.</span>
                  </div>
                  <div class="reservation-card__row">{{ booking.client.phone }}</div>
                  <div class="reservation-card__row">{{ booking.comment }}</div>
                  <div v-if="booking.status === 'wait' || booking.status === 'open'" class="reservation-card__update">
                    <span v-if="booking.status === 'wait'">Ожидание</span>
                    <span v-if="booking.status === 'open'">До закрытия</span>
                    <span> {{ booking.booking_time }}</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="(selected_table || selected_booking) && !reservation_form_visible && !transfer_form_visible"
            class="table-card"
            :class="{
              'table-card--wait': selected_table_data.status === 'wait',
              'table-card--open': selected_table_data.status === 'open'
            }"
          >
            <template v-if="selected_table && reservation_tab === 'table'">
              <div class="table-card__top">
                <div class="table-card__info">
                  <div
                    v-if="selected_table_data.bookings.length"
                    class="table-card__text table-card__text--main"
                  >
                    <span v-if="selected_table_data.status !== 'open'">Свободен до {{
                        selected_table_data.bookings[0].short_time_from
                      }}</span>
                    <span v-else>Забронирован до {{ selected_table_data.bookings[0].short_time_to }}</span>
                  </div>
                  <div
                    v-if="selected_table_data.status !== 'open'"
                    class="table-card__text"
                  >
                    <span v-if="selected_table_data.status !== 'wait' && selected_table_data.bookings.length > 0">До брони: {{ selected_table_data.booking_time }}</span>
                    <span v-if="selected_table_data.status === 'wait'">Ожидание: {{ selected_table_data.booking_time }}</span>
                  </div>
                  <div class="table-card__row">
                    <IconPeople/>
                    <span>{{ selected_table_data.count_place }}</span>
                  </div>
                </div>
                <div
                  v-if="selected_table_data.number === 11 || selected_table_data.number === 15 || selected_table_data.number === 17"
                  class="table-card__img"
                >
                  <IconSix/>
                </div>
                <div
                  v-if="selected_table_data.number === 18 || selected_table_data.number === 20"
                  class="table-card__img"
                >
                  <IconFourCross/>
                </div>
                <div
                  v-if="selected_table_data.number === 19"
                  class="table-card__img"
                >
                  <IconTwoRotate/>
                </div>
                <div
                  v-if="selected_table_data.number === 21 || selected_table_data.number === 22"
                  class="table-card__img"
                >
                  <IconTwo/>
                </div>
                <div
                  v-if="selected_table_data.number === 13"
                  class="table-card__img"
                >
                  <IconFour/>
                </div>
                <div
                  v-if="selected_table_data.number === 14"
                  class="table-card__img"
                >
                  <IconTwoLeft/>
                </div>
                <div
                  v-if="selected_table_data.number === 12"
                  class="table-card__img"
                >
                  <IconTwoRight/>
                </div>
                <div
                  v-if="selected_table_data.number === 16"
                  class="table-card__img"
                >
                  <IconSixRound/>
                </div>
                <div
                  v-if="selected_table_data.number === 32 || selected_table_data.number === 34 || selected_table_data.number === 37 || selected_table_data.number === 49 || selected_table_data.number === 52 || selected_table_data.number === 54 || selected_table_data.number === 57"
                  class="table-card__img"
                >
                  <IconFourVeranda />
                </div>
                <div
                  v-if="selected_table_data.number === 33 || selected_table_data.number === 35 || selected_table_data.number === 36 || selected_table_data.number === 51 || selected_table_data.number === 55 || selected_table_data.number === 56 || selected_table_data.number === 46"
                  class="table-card__img"
                >
                  <IconSixVeranda />
                </div>
                <div
                  v-if="selected_table_data.number === 31 || selected_table_data.number === 41 || selected_table_data.number === 42 || selected_table_data.number === 43 || selected_table_data.number === 44 || selected_table_data.number === 45 || selected_table_data.number === 47 || selected_table_data.number === 48 || selected_table_data.number === 53 || selected_table_data.number === 61 || selected_table_data.number === 62 || selected_table_data.number === 63 || selected_table_data.number === 64"
                  class="table-card__img"
                >
                  <IconTwoVeranda />
                </div>
                <div
                  v-if="selected_table_data.number === 1 || selected_table_data.number === 2"
                  class="table-card__img"
                >
                  <ChefRotate />
                </div>
                <div
                  v-if="selected_table_data.number === 9 || selected_table_data.number === 10"
                  class="table-card__img"
                >
                  <ChefRotateReverse />
                </div>
                <div
                  v-if="selected_table_data.number === 3 || selected_table_data.number === 4 || selected_table_data.number === 5 || selected_table_data.number === 6 || selected_table_data.number === 7 || selected_table_data.number === 8"
                  class="table-card__img"
                >
                  <ChefNormal />
                </div>
              </div>
              <div class="table-card__list">
                <div
                  v-for="(booking, index) in selected_table_data.bookings"
                  :key="booking.id"
                  class="table-card__reserve"
                >
                  Бронь №{{ index + 1 }}: <span v-if="selected_table_data.bookings">с {{ booking.short_time_from }} до {{
                    booking.short_time_to
                  }}</span>
                </div>
              </div>
              <div class="table-card__controls">
                <button
                  @click="onOpenForm"
                  type="button"
                  class="sidebar__btn"
                >
                  Забронировать
                </button>
              </div>
            </template>
            <template v-else-if="selected_table && reservation_tab === 'reservations'">
              <div class="reservation-card">
                <div v-if="!selected_table_data.bookings.length" class="reservation-card__empty">Нет заявок</div>
                <button
                  v-for="(booking, index) in selected_table_data.bookings"
                  :key="booking.id"
                  @click="onReservationCardClick(booking.id, index, booking)"
                  type="button"
                  class="reservation-card__item"
                  :class="{
                  'reservation-card__item--wait': booking.status === 'wait',
                  'reservation-card__item--open': booking.status === 'open'
                }"
                >
                  <div v-if="!booking.is_read" class="reservation-card__new">Новая заявка</div>
                  <div class="reservation-card__top">
                    <div>
                      <b v-if="booking.client.name">{{ booking.client.name }},</b>
                      <span> {{ booking.count_guests }} чел.</span>
                    </div>
                    <div class="reservation-card__time">{{ booking.short_time_from }}
                      - {{ booking.short_time_to }}
                    </div>
                  </div>
                  <span class="reservation-card__row"></span>
                  <div class="reservation-card__row">{{ booking.client.phone }}</div>
                  <div class="reservation-card__row">
                    <span class="reservation-card__theme">Стол №{{ selected_table_data.number }}</span>
                    <span>. {{ selected_table_data.hall === 1 ? 'Каминный зал' : selected_table_data.hall === 3 ? 'Веранда' : 'Chef’s table' }}</span>
                  </div>
                  <div v-if="booking.invoice" class="reservation-card__between">
                    <div class="reservation-card__row">
                      Депозит: <span class="reservation-card__theme">{{ booking.invoice ? booking.invoice.amount : 0 }}₽</span>
                    </div>
                    <div class="reservation-card__row">
                      Внесено: <span class="reservation-card__theme">{{ booking.invoice.is_paid ? booking.invoice.amount : 0 }}₽</span>
                    </div>
                  </div>
                  <div class="reservation-card__row">{{ booking.comment }}</div>
                  <div v-if="booking.status === 'wait' || booking.status === 'open'" class="reservation-card__update">
                    <span v-if="booking.status === 'wait'">Ожидание</span>
                    <span v-if="booking.status === 'open'">До закрытия</span>
                    <span> {{ booking.booking_time }}</span>
                  </div>
                </button>
              </div>
            </template>
            <template v-if="selected_booking">
              <div class="table-card__top">
                <div class="table-card__info">
                  <div
                    v-if="selected_table_data.bookings.length"
                    class="table-card__text table-card__text--main"
                  >
                    <span v-if="selected_table_data.status !== 'open'">Свободен до {{
                        selected_table_data.bookings[0].short_time_from
                      }}</span>
                    <span v-else>Забронирован до {{ selected_table_data.bookings[0].short_time_to }}</span>
                  </div>
                  <div
                    v-if="selected_table_data.status !== 'open'"
                    class="table-card__text"
                  >
                    <span v-if="selected_table_data.status !== 'wait'">До брони: {{
                        selected_table_data.booking_time
                      }}</span>
                    <span v-else>Ожидание: {{ selected_table_data.booking_time }}</span>
                  </div>
                  <div class="table-card__row">
                    <IconPeople/>
                    <span>{{ selected_table_data.count_place }}</span>
                  </div>
                </div>
                <div
                  v-if="selected_table_data.number === 11 || selected_table_data.number === 15 || selected_table_data.number === 17"
                  class="table-card__img"
                >
                  <IconSix/>
                </div>
                <div
                  v-if="selected_table_data.number === 18 || selected_table_data.number === 20"
                  class="table-card__img"
                >
                  <IconFourCross/>
                </div>
                <div
                  v-if="selected_table_data.number === 19"
                  class="table-card__img"
                >
                  <IconTwoRotate />
                </div>
                <div
                  v-if="selected_table_data.number === 21 || selected_table_data.number === 22"
                  class="table-card__img"
                >
                  <IconTwo/>
                </div>
                <div
                  v-if="selected_table_data.number === 13"
                  class="table-card__img"
                >
                  <IconFour/>
                </div>
                <div
                  v-if="selected_table_data.number === 14"
                  class="table-card__img"
                >
                  <IconTwoLeft/>
                </div>
                <div
                  v-if="selected_table_data.number === 12"
                  class="table-card__img"
                >
                  <IconTwoRight/>
                </div>
                <div
                  v-if="selected_table_data.number === 16"
                  class="table-card__img"
                >
                  <IconSixRound/>
                </div>
                <div
                  v-if="selected_table_data.number === 32 || selected_table_data.number === 34 || selected_table_data.number === 37 || selected_table_data.number === 49 || selected_table_data.number === 52 || selected_table_data.number === 54 || selected_table_data.number === 57"
                  class="table-card__img"
                >
                  <IconFourVeranda />
                </div>
                <div
                  v-if="selected_table_data.number === 33 || selected_table_data.number === 35 || selected_table_data.number === 36 || selected_table_data.number === 51 || selected_table_data.number === 55 || selected_table_data.number === 56 || selected_table_data.number === 46"
                  class="table-card__img"
                >
                  <IconSixVeranda />
                </div>
                <div
                  v-if="selected_table_data.number === 31 || selected_table_data.number === 41 || selected_table_data.number === 42 || selected_table_data.number === 43 || selected_table_data.number === 44 || selected_table_data.number === 45 || selected_table_data.number === 47 || selected_table_data.number === 48 || selected_table_data.number === 53 || selected_table_data.number === 61 || selected_table_data.number === 62 || selected_table_data.number === 63 || selected_table_data.number === 64"
                  class="table-card__img"
                >
                  <IconTwoVeranda />
                </div>
                <div
                  v-if="selected_table_data.number === 1 || selected_table_data.number === 2"
                  class="table-card__img"
                >
                  <ChefRotate />
                </div>
                <div
                  v-if="selected_table_data.number === 9 || selected_table_data.number === 10"
                  class="table-card__img"
                >
                  <ChefRotateReverse />
                </div>
                <div
                  v-if="selected_table_data.number === 3 || selected_table_data.number === 4 || selected_table_data.number === 5 || selected_table_data.number === 6 || selected_table_data.number === 7 || selected_table_data.number === 8"
                  class="table-card__img"
                >
                  <ChefNormal />
                </div>
              </div>
              <div class="reservation-card">
                <button
                  type="button"
                  class="reservation-card__item reservation-card__item--big"
                  :class="{
                  'reservation-card__item--wait': booking_current.status === 'wait',
                  'reservation-card__item--open': booking_current.status === 'open'
                }"
                >
                  <div class="reservation-card__top">
                    <div class="reservation-card__number">Бронь №{{ booking_current.id }}</div>
                    <div class="reservation-card__time">{{ booking_current.short_time_from }}
                      - {{ booking_current.short_time_to }}
                    </div>
                  </div>
                  <div class="reservation-card__row">Кем создана: {{ booking_current.created_by_name ? 'Хостес' : 'Сайт' }}</div>
                  <div v-if="booking_current.created_at" class="reservation-card__row">Дата создания: {{ booking_current.created_at }}</div>
<!--                  <div class="reservation-card__row">-->
<!--                    <span class="reservation-card__theme">Стол №{{ selected_table_data.number }}</span>-->
<!--                    <span>. {{ selected_table_data.number >= 11 ? 'Каминный зал' : 'Chef’s table' }}</span>-->
<!--                  </div>-->
                  <div class="reservation-card__row">
                    <b v-if="booking_current.client.name">{{ booking_current.client.name }},</b>
                    <span> {{ booking_current.count_guests }} чел.</span>
                  </div>
                  <div class="reservation-card__row">{{ booking_current.client.phone }}</div>
                  <div v-if="booking_current.invoice" class="reservation-card__between">
                    <div>
                      Депозит: <span class="reservation-card__theme">{{ booking_current.invoice ? booking_current.invoice.amount : 0 }}₽</span>
                    </div>
                    <div>
                      Внесено: <span class="reservation-card__theme">{{ booking_current.invoice.is_paid ? booking_current.invoice.amount : 0 }}₽</span>
                    </div>
                  </div>
                  <div v-if="booking_current.comment" class="reservation-card__row">{{ booking_current.comment }}</div>
                  <div v-if="!booking_current.is_read" class="reservation-card__tag">Новая</div>
                  <div v-if="booking_current.status === 'wait' || booking_current.status === 'open'"
                       class="reservation-card__update">
                    <span v-if="booking_current.status === 'wait'">Ожидание</span>
                    <span v-if="booking_current.status === 'open'">До закрытия</span>
                    <span> > {{ booking_current.booking_time }}</span>
                  </div>
                  <div v-if="booking_current.invoice && booking_current.invoice.is_paid" class="reservation-card__between reservation-card__between--mt">
                    <div class="reservation-card__keeper">
                      <div class="reservation-card__keeper-logos">
                        <Check v-if="booking_current.rk_uid" class="reservation-card__keeper-mark" />
                        <Cross v-else class="reservation-card__keeper-mark" />
                        <Keeper />
                      </div>
                      <span>Бронь</span>
                    </div>
                    <div class="reservation-card__keeper">
                      <div class="reservation-card__keeper-logos">
                        <Check v-if="booking_current.invoice.rk_uid" class="reservation-card__keeper-mark" />
                        <Cross v-else class="reservation-card__keeper-mark" />
                        <Keeper />
                      </div>
                      <span>Платеж</span>
                    </div>
                  </div>
                </button>
              </div>
              <div class="controls" v-if="!booking_current.is_read">
                <button
                  @click="onBookingIsRead(booking_current.id)"
                  type="button"
                  class="controls__item"
                >
                  Подтвердить заявку
                </button>
              </div>
              <div class="controls controls--quad">
                <button
                  @click="onPatchBooking(booking_current.id, 'new')"
                  type="button"
                  class="controls__item"
                  :disabled="booking_current.status === 'open' || booking_current.status === 'new' || booking_current.status === 'wait'"
                >
                  Новая
                </button>
                <button
                  @click="onPopupOpen(booking_current.id, 'open')"
                  type="button"
                  class="controls__item"
                  :disabled="booking_current.status === 'open'"
                >
                  Открыть
                </button>
                <button
                  @click="onPopupOpen(booking_current.id, 'close')"
                  type="button"
                  class="controls__item"
                  :disabled="booking_current.status === 'new'"
                >
                  Закрыть
                </button>
                <button
                  @click="onPopupOpen(booking_current.id, 'cancel')"
                  type="button"
                  class="controls__item"
                  :disabled="booking_current.status === 'open'"
                >
                  Отменить
                </button>
              </div>
              <div class="controls controls--double">
                <button
                  type="button"
                  class="controls__item"
                  @click="onBookingContinue"
                  :disabled="booking_current.status !== 'wait'"
                >
                  Сдвинуть
                </button>
                <button
                  @click="onReservationUpdate(booking_current.id)"
                  type="button"
                  class="controls__item"
                >
                  Редактировать
                </button>
              </div>
              <div class="controls">
                <button
                  @click="onBookingTransfer(booking_current.id)"
                  type="button"
                  class="controls__item"
                >
                  Пересадить
                </button>
              </div>
              <div v-if="!booking_current.add_calendar" class="controls">
                <button
                  @click="onAddToCalendar(booking_current.id)"
                  type="button"
                  class="controls__item controls__item--green"
                >
                  Добавить в календарь шефа
                </button>
              </div>
              <div v-else class="controls">
                <button
                  @click="onRemoveFromCalendar(booking_current.id)"
                  type="button"
                  class="controls__item controls__item--red"
                >
                  Удалить из календаря шефа
                </button>
              </div>
              <div v-if="booking_current.invoice" class="sidebar__status">
                <span
                  v-if="booking_current.sms && booking_current.sms.status"
                  class="sidebar__status-head"
                >
                  Статус отправки СМС:
                </span>
                <span
                  v-if="booking_current.sms && booking_current.sms.status"
                  class="sidebar__status-text"
                >
                  {{ booking_current.sms.status }}
                </span>
                <div class="controls controls--no-indent">
                  <button
                    @click="onSMSResend(booking_current.id)"
                    type="button"
                    class="controls__item"
                  >
                    Отправить СМС повторно
                  </button>
                </div>
              </div>
              <div v-if="(booking_current.invoice && booking_current.invoice.is_paid) && booking_current.rk_uid" class="controls">
                <button
                  @click="onKeeperPopup(booking_current.id)"
                  type="button"
                  class="controls__item"
                >
                  Информация из r_keeper
                </button>
              </div>
              <div v-if="(booking_current.invoice && booking_current.invoice.is_paid) && !booking_current.rk_uid" class="controls">
                <button
                  @click="onKeeperBook(booking_current.id)"
                  type="button"
                  class="controls__item"
                  :class="{ 'controls__item--load': keeper_wait }"
                >
                  <span>Создать бронь в r-keeper</span>
                  <Preloader class="sidebar__preloader" />
                </button>
              </div>
              <div v-if="booking_current.status === 'open' && booking_current.rk_uid && (booking_current.invoice && !booking_current.invoice.rk_uid)" class="controls">
                <button
                  @click="onKeeperPrepay(booking_current.id)"
                  type="button"
                  class="controls__item"
                  :class="{ 'controls__item--load': keeper_prepay_wait }"
                >
                  <span>Внести предоплату в r_keeper</span>
                  <Preloader class="sidebar__preloader" />
                </button>
              </div>
            </template>
          </div>
          <form
            v-if="reservation_form_visible"
            @submit.prevent
            class="sidebar__form"
          >
            <div class="sidebar-back">
              <button @click="onBackFromForm" type="button" class="sidebar-back__btn">
                <IconBack/>
                <span>Назад</span>
              </button>
              <button
                v-if="!reservation_form_readonly"
                @click="onFillForm"
                type="button"
                class="sidebar-back__btn sidebar-back__btn--full"
              >
                Посадить гостя
              </button>
              <div v-if="reservation_form_readonly" class="sidebar__title">Редактирование</div>
            </div>
            <div class="sidebar__input" :class="{ 'sidebar__input--error': $v.reservation_form.time_from.$error }">
              <label for="date_from">Дата бронирования, с<sup>*</sup></label>
              <input
                @change="$v.reservation_form.time_from.$touch; plusTheeHours()"
                id="date_from"
                type="datetime-local"
                name="date_from"
                v-model="reservation_form.time_from"
                :disabled="form_filled"
                :min="new Date()"
              >
              <div class="sidebar__error" v-if="$v.reservation_form.time_from.$dirty && !$v.reservation_form.time_from.required">
                Обязательное поле
              </div>
            </div>
            <div class="sidebar__input" :class="{ 'sidebar__input--error': $v.reservation_form.time_to.$error }">
              <label for="date_to">Дата бронирования, по<sup>*</sup></label>
              <input
                @change="$v.reservation_form.time_to.$touch"
                id="date_to"
                type="datetime-local"
                name="date_to"
                v-model="reservation_form.time_to"
              >
              <div class="sidebar__error" v-if="$v.reservation_form.time_to.$dirty && !$v.reservation_form.time_to.required">
                Обязательное поле
              </div>
            </div>
            <div v-if="!reservation_form_readonly && current_hall === 1" class="sidebar__input">
              <label class="checkbox">
                <div class="checkbox__text">Забронировать без сета</div>
                <input type="checkbox" v-model="no_set" />
                <div class="checkbox__checkmark"></div>
              </label>
            </div>
            <div class="sidebar__input" :class="{ 'sidebar__input--error': $v.reservation_form.count_guests.$error }">
              <label for="count_guests">Количество гостей<sup>*</sup></label>
              <input
                @change="$v.reservation_form.count_guests.$touch"
                id="count_guests"
                type="number"
                min="1"
                max="8"
                placeholder="Введите количество гостей"
                v-model="reservation_form.count_guests"
              >
              <div class="sidebar__error" v-if="$v.reservation_form.count_guests.$dirty && !$v.reservation_form.count_guests.required">
                Обязательное поле
              </div>
            </div>
            <div class="sidebar__input" :class="{ 'sidebar__input--error': $v.reservation_form.phone.$error }">
              <label for="phone">Телефон<sup>*</sup></label>
              <masked-input
                @change="$v.reservation_form.phone.$touch"
                v-model.trim="reservation_form.phone"
                mask="\+71111111111"
                placeholder-char=" "
                placeholder="Введите номер телефона"
                id="phone"
                type="tel"
                name="phone"
                autocomplete="off"
                :readonly="reservation_form_readonly"
              />
              <div class="sidebar__error" v-if="$v.reservation_form.phone.$dirty && !$v.reservation_form.phone.required">
                Обязательное поле
              </div>
            </div>
            <div v-if="reservation_form_sms_visible && current_hall !== 3 && reservation_form.time_from.substr(11, 13) >= sms_time" class="sidebar__input">
              <label class="checkbox">
                <div class="checkbox__text">Отправить SMS на оплату</div>
                <input type="checkbox" v-model="reservation_form.send_sms" />
                <div class="checkbox__checkmark"></div>
              </label>
            </div>
            <div class="sidebar__input" :class="{ 'sidebar__input--error': $v.reservation_form.name.$error }">
              <label for="name">Имя гостя<sup>*</sup></label>
              <input
                @change="$v.reservation_form.name.$touch"
                id="name"
                type="text"
                name="name"
                placeholder="Имя гостя"
                autocomplete="off"
                v-model="reservation_form.name"
                :readonly="reservation_form_readonly"
              >
              <div class="sidebar__error" v-if="$v.reservation_form.name.$dirty && !$v.reservation_form.name.required">
                Обязательное поле
              </div>
            </div>
            <div class="sidebar__input" :class="{ 'sidebar__input--error': $v.reservation_form.email.$error }">
              <label for="email">Email</label>
                <input
                  @change="$v.reservation_form.email.$touch"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Введите email"
                  autocomplete="off"
                  v-model="reservation_form.email"
                  :readonly="reservation_form_readonly"
                >
              <div class="sidebar__error" v-if="$v.reservation_form.email.$dirty && !$v.reservation_form.email.email">
                Введите правильный формат email
              </div>
            </div>
            <div class="sidebar__input">
              <label for="comment">Комментарий</label>
              <textarea
                id="comment"
                name="comment"
                placeholder="Введите комментарий"
                autocomplete="off"
                v-model="reservation_form.comment"
              />
            </div>
            <button
              v-if="!reservation_form_readonly"
              type="button"
              class="sidebar__btn"
              :class="{ 'sidebar__btn--load': submit_wait }"
              @click="onSubmitReservation(selected_table_data.id)"
            >
              <span>Забронировать</span>
              <Preloader class="sidebar__preloader" />
            </button>
            <button
              v-if="reservation_form_readonly"
              type="button"
              class="sidebar__btn"
              :class="{ 'sidebar__btn--load': submit_wait }"
              @click="onUpdateReservation(selected_table_data.id)"
            >
              <span>Сохранить</span>
              <Preloader class="sidebar__preloader" />
            </button>
          </form>
          <form
            v-if="transfer_form_visible"
            @submit.prevent
            class="sidebar__form"
          >
            <div class="sidebar-back">
              <button @click="onBackFromForm" type="button" class="sidebar-back__btn">
                <IconBack/>
                <span>Назад</span>
              </button>
              <div class="sidebar__title">Пересадка гостя</div>
            </div>
            <div class="sidebar__input">
              <label for="t_table">Стол</label>
              <input
                id="t_table"
                type="text"
                placeholder="Введите номер стола"
                v-model.number="transfer_form.table"
              >
            </div>
            <div class="sidebar__input">
              <label for="t_count_guests">Количество гостей</label>
              <input
                id="t_count_guests"
                type="number"
                placeholder="Введите количество гостей"
                v-model="transfer_form.count_guests"
              >
            </div>
<!--            <div class="sidebar__input">-->
<!--              <label for="t_comment">Комментарий</label>-->
<!--              <textarea-->
<!--                id="t_comment"-->
<!--                name="comment"-->
<!--                placeholder="Введите комментарий"-->
<!--                autocomplete="off"-->
<!--                v-model="transfer_form.comment"-->
<!--              />-->
<!--            </div>-->
            <div class="sidebar__input">
              <label class="checkbox">
                <div class="checkbox__text">Редактировать время</div>
                <input type="checkbox" v-model="transfer_form.change_date" />
                <div class="checkbox__checkmark"></div>
              </label>
            </div>
            <div class="sidebar__input">
              <label>Дата бронирования, с</label>
              <input
                type="datetime-local"
                v-model="transfer_form.time_from"
                :disabled="!transfer_form.change_date"
              >
            </div>
            <div class="sidebar__input">
              <label>Дата бронирования, по</label>
              <input
                type="datetime-local"
                v-model="transfer_form.time_to"
                :disabled="!transfer_form.change_date"
              >
            </div>
            <div class="sidebar__input">
              <label>Имя гостя</label>
              <input
                type="text"
                name="name"
                placeholder="Имя гостя"
                autocomplete="off"
                v-model="transfer_form.name"
                disabled
              >
            </div>
            <div class="sidebar__input">
              <label>Телефон</label>
              <input
                type="text"
                name="name"
                placeholder="Имя гостя"
                autocomplete="off"
                v-model="transfer_form.phone"
                disabled
              >
            </div>
            <button
              type="button"
              class="sidebar__btn"
              :class="{ 'sidebar__btn--load': submit_wait }"
              @click="onUpdateTransfer(selected_table_data.id)"
            >
              <span>Пересадить</span>
              <Preloader class="sidebar__preloader" />
            </button>
          </form>
        </div>
        <div v-if="!reservation_form_visible && !selected_booking" class="sidebar__bottom">
          <div v-if="!selected_table && !searchBookingsQuery" class="sidebar__counters">
            <div class="sidebar__counter" :class="{'sidebar__counter--wait': this.filter === 'wait'}">
              <input
                @click="onFilterSelect"
                id="filter_wait"
                type="radio"
                value="wait"
                v-model="filter"
              >
              <label for="filter_wait">
                <div class="sidebar__count">{{ filterCounter.wait }}</div>
                <div class="sidebar__desc">Ожидание</div>
              </label>
            </div>
            <div class="sidebar__counter" :class="{'sidebar__counter--open': this.filter === 'open'}">
              <input
                @click="onFilterSelect"
                id="filter_open"
                type="radio"
                value="open"
                v-model="filter"
              >
              <label for="filter_open">
                <div class="sidebar__count">{{ filterCounter.open }}</div>
                <div class="sidebar__desc">Открытые</div>
              </label>
            </div>
            <div class="sidebar__counter" :class="{'sidebar__counter--new': this.filter === 'new'}">
              <input
                @click="onFilterSelect"
                id="filter_new"
                type="radio"
                value="new"
                v-model="filter"
              >
              <label for="filter_new">
                <div class="sidebar__count">{{ filterCounter.new }}</div>
                <div class="sidebar__desc">Новые</div>
              </label>
            </div>
          </div>
          <div v-if="!selected_table" class="sidebar__search">
            <button class="sidebar__search-btn" type="button">
              <IconSearch/>
            </button>
            <input type="search" v-model="searchBookingsQuery" @input="onSearch" placeholder="Номер телефона или имя гостя">
          </div>
          <div v-if="selected_table" class="sidebar__bottom-tabs">
            <div class="sidebar__bottom-tab">
              <input
                id="table"
                type="radio"
                value="table"
                name="reservation_tab"
                v-model="reservation_tab"
                checked
              >
              <label for="table">Стол №{{ selected_table_data.number }}</label>
            </div>
            <div class="sidebar__bottom-tab">
              <input
                id="reservations"
                type="radio"
                value="reservations"
                name="reservation_tab"
                v-model="reservation_tab"
              >
              <label for="reservations">Заявки стола</label>
            </div>
          </div>
        </div>
      </div>
    </aside>
    <main class="booking__main">
      <div class="booking__top">
        <div class="booking__title">
          <div class="booking__controls">
            <button type="button" @click="$modal.show('support')">
              Поддержка
              <IconWarning v-if="tables.balance_sms < 200" class="booking__warning" />
            </button>
            <button type="button" @click="onSwitchContent">
              <span v-if="showStat">Схема зала</span>
              <span v-else>График</span>
              <span v-if="!showStat && tables.unread_booking" class="unread-booking">{{ tables.unread_booking }}</span>
            </button>
          </div>
          <div class="booking__guests">
            <div class="booking__guests-legends">
              <div class="booking__guests-legend booking__guests-legend--all">
                Всего:
                <span>{{ stat_guests.all }}</span>
              </div>
              <div class="booking__guests-legend booking__guests-legend--wait">
                Ждем:
                <span>{{ stat_guests.wait }}</span>
              </div>
              <div class="booking__guests-legend booking__guests-legend--hall">
                В зале:
                <span>{{ stat_guests.in_hall }}</span>
              </div>
              <div class="booking__guests-legend booking__guests-legend--done">
                Закрыто:
                <span>{{ stat_guests.done }}</span>
              </div>
            </div>
            <div class="booking__guests-line">
              <div v-if="stat_guests.wait" class="booking__guests-line-item booking__guests-line-item--wait" :style="{width: `${waitGuestWidth}%`}"></div>
              <div v-if="stat_guests.in_hall" class="booking__guests-line-item booking__guests-line-item--hall" :style="{width: `${inHallGuestWidth}%`}"></div>
              <div v-if="stat_guests.done" class="booking__guests-line-item booking__guests-line-item--done" :style="{width: `${doneGuestWidth}%`}"></div>
            </div>
          </div>
<!--          <div class="booking__filters">-->
<!--            <div class="booking__filter">-->
<!--              <input-->
<!--                id="3"-->
<!--                type="radio"-->
<!--                value="3"-->
<!--                name="hall"-->
<!--                v-model="hall_filter"-->
<!--                checked-->
<!--              >-->
<!--              <label for="3">Все залы</label>-->
<!--            </div>-->
<!--            <div-->
<!--              v-for="filter in tables.halls"-->
<!--              :key="filter.id"-->
<!--              class="booking__filter"-->
<!--            >-->
<!--              <input-->
<!--                :id="filter.id"-->
<!--                type="radio"-->
<!--                :value="filter.id"-->
<!--                name="hall"-->
<!--                v-model="hall_filter"-->
<!--              >-->
<!--              <label :for="filter.id">{{ filter.name }}</label>-->
<!--            </div>-->
<!--          </div>-->
          <div class="booking__date">
            <VueCtkDateTimePicker
              v-model="date_time"
              label="Выберите дату и время"
              format="DD.MM.YYYY HH:mm"
              @is-hidden="onInputPicker"
              @input="onInputDate"
              @is-shown="onFormatPicker"
              dark
              no-header
              no-label
              right
              color="#b88337"
              button-color="#b88337"
              overlay
              no-keyboard
              button-now-translation="Сейчас"
              locale="ru"
              input-size="sm"
              noClearButton
              :min-date="min_date"
            />
          </div>
        </div>
      </div>
      <div class="booking__content">
        <transition-group name="fade" tag="div">
          <div class="stat" v-if="showStat" key="stat">
            <div class="stat__title">
              <span>Оплачено через сайт</span>
              <button @click="exportExcel" type="button" class="stat__download">Скачать в Excel</button>
            </div>
            <div class="stat__blocks">
              <div class="stat__block">
                <div class="stat__block-title">Всего</div>
                <div class="stat__block-text">{{ stat_pay.total_pay || 0 }} &#8381;</div>
              </div>
              <div class="stat__block">
                <div class="stat__block-title">Возвраты</div>
                <div class="stat__block-text">{{ stat_pay.refunds || 0 }} / {{ stat_pay.refunds_sum || 0 }} &#8381;</div>
              </div>
              <div class="stat__block">
                <div class="stat__block-title">Сегодня / Вчера</div>
                <div class="stat__block-text">{{ stat_pay.today_pay || 0 }} &#8381; / {{ stat_pay.yesterday_pay || 0 }} &#8381;</div>
              </div>
              <div class="stat__block">
                <div class="stat__block-title">Нет в r_keeper</div>
                <div class="stat__block-text">{{ stat_pay.not_rk || 0 }} / {{ stat_pay.not_rk_sum || 0 }} &#8381;</div>
              </div>
              <div class="stat__block">
                <div class="stat__block-title">Не реализовано</div>
                <div class="stat__block-text">{{ stat_pay.not_closed || 0 }} &#8381;</div>
              </div>
            </div>
            <div class="stat__head">
              <span>Дата</span>
              <span>Гости</span>
              <span>Брони</span>
              <span>Новые</span>
              <span>Б / Д</span>
              <span>Д</span>
              <span>Д / +</span>
              <span>Д / -</span>
            </div>
            <button
              @click="onStatClick(item[0])"
              v-for="(item, index) in stat"
              :key="index"
              class="stat__item"
              :class="{ 'stat__item--green': item[1]['not_viewed'] > 0 }"
              type="button"
            >
              <span>{{ item[0] }}</span>
              <span>{{ item[1]['count_guests'] }}</span>
              <span>{{ item[1]['all'] }}</span>
              <span>{{ item[1]['not_viewed'] }}</span>
              <span>{{ item[1]['without_deposit'] }}</span>
              <span>{{ item[1]['with_deposit'] }}</span>
              <span>{{ item[1]['payed'] }} ({{ item[1]['payed_sum'] }} &#8381;)</span>
              <span>{{ item[1]['not_payed'] }} ({{ item[1]['not_payed_sum'] }} &#8381;)</span>
            </button>
          </div>
          <div key="schema" v-else>
            <transition name="fade">
              <div v-if="!this.black_bg" class="table table--scheme" :class="{'table--veranda': current_hall === 3, 'table--chef': current_hall === 2 }">
                <div class="table__switchers">
                  <button
                    v-for="hall in hallsSwitchers"
                    :key="hall.id"
                    @click="onChangeHall(hall.number)"
                    class="table__switcher"
                    type="button"
                  >
                    <component :is="hall.icon" />
                  </button>
                </div>
                <div
                  @click="onOverlayHide"
                  class="booking__overlay"
                  :class="{ 'booking__overlay--visible': overlay_visible }"
                >
                </div>
                <button
                  v-for="table in current_tables"
                  :key="table.id"
                  @click="onTableClick(table)"
                  type="button"
                  class="table__item"
                  :class="[
              {
                'table__item--selected': selected_table === table.id,
                'table__item--wait': table.status === 'wait',
                'table__item--open': table.status === 'open'
              },
              `table__item--${table.number}`
            ]"
                >
                  <div v-if="table.bookings.length && table.status !== 'wait'" class="table__holder">
                    <div class="table__holder-top">
                      <span v-if="table.status !== 'open'">Свободен до {{ table.bookings[0].short_time_from }}</span>
                      <span v-else>Забронирован до {{ table.bookings[0].short_time_to }}</span>
                      <span v-if="table.status !== 'open'" class="table__holder-estimate">До брони: {{
                          table.booking_time
                        }}</span>
                      <span v-if="table.deposit">Депозит: {{ table.deposit }}₽</span>
                    </div>
                    <div v-if="table.status !== 'open'" class="table__holder-bottom">
                      <span>Ближайшая бронь</span>
                      <span>с {{ table.bookings[0].short_time_from }} до {{ table.bookings[0].short_time_to }}</span>
                    </div>
                    <div v-if="table.status === 'open' && table.bookings.length > 1" class="table__holder-bottom">
                      <span>Ближайшая бронь</span>
                      <span>с {{ table.bookings[1].short_time_from }} до {{ table.bookings[1].short_time_to }}</span>
                    </div>
                  </div>
                  <div class="table__top">
                    <div v-if="!table.bookings.length || table.status === 'wait'" class="table__status" :class="{ 'table__status--deposit': table.deposit }">
                      {{ table.status === 'free' ? 'Свободен' : 'Ожидание' }}
                      <div v-if="table.deposit">Депозит: {{ table.deposit }}₽</div>
                    </div>
                    <!--              <div v-if="!table.bookings.length || table.status === 'wait'" class="table__indicator"></div>-->
                  </div>
                  <div
                    v-if="table.number === 11 || table.number === 15 || table.number === 17"
                    class="table__img"
                  >
                    <IconSix/>
                  </div>
                  <div
                    v-if="table.number === 18 || table.number === 20"
                    class="table__img"
                  >
                    <IconFourCross/>
                  </div>
                  <div
                    v-if="table.number === 19"
                    class="table__img"
                  >
                    <IconTwoRotate />
                  </div>
                  <div
                    v-if="table.number === 21 || table.number === 22"
                    class="table__img"
                  >
                    <IconTwo/>
                  </div>
                  <div
                    v-if="table.number === 13"
                    class="table__img"
                  >
                    <IconFour/>
                  </div>
                  <div
                    v-if="table.number === 14"
                    class="table__img"
                  >
                    <IconTwoLeft/>
                  </div>
                  <div
                    v-if="table.number === 12"
                    class="table__img"
                  >
                    <IconTwoRight />
                  </div>
                  <div
                    v-if="table.number === 16"
                    class="table__img"
                  >
                    <IconSixRound />
                  </div>
                  <div
                    v-if="table.number === 32 || table.number === 34 || table.number === 37 || table.number === 49 || table.number === 52 || table.number === 54 || table.number === 57"
                    class="table__img"
                  >
                    <IconFourVeranda />
                  </div>
                  <div
                    v-if="table.number === 33 || table.number === 35 || table.number === 36 || table.number === 51 || table.number === 55 || table.number === 56 || table.number === 46"
                    class="table__img"
                  >
                    <IconSixVeranda />
                  </div>
                  <div
                    v-if="table.number === 31 || table.number === 41 || table.number === 42 || table.number === 43 || table.number === 44 || table.number === 45 || table.number === 47 || table.number === 48 || table.number === 53 || table.number === 61 || table.number === 62 || table.number === 63 || table.number === 64"
                    class="table__img"
                  >
                    <IconTwoVeranda />
                  </div>
                  <div
                    v-if="table.number === 1 || table.number === 2"
                    class="table__img"
                  >
                    <ChefRotate />
                  </div>
                  <div
                    v-if="table.number === 9 || table.number === 10"
                    class="table__img"
                  >
                    <ChefRotateReverse />
                  </div>
                  <div
                    v-if="table.number === 3 || table.number === 4 || table.number === 5 || table.number === 6 || table.number === 7 || table.number === 8"
                    class="table__img"
                  >
                    <ChefNormal />
                  </div>
                  <div class="table__bottom">
                    <div class="table__name">{{ table.hall === 1 ? 'Каминный зал' : table.hall === 3 ? 'Веранда' : 'Chef’s table' }}</div>
                    <div class="table__row">
                      <div class="table__number"><b>№{{ table.number }}</b> <span class="table__table">стол</span></div>
                      <div class="table__count">
                        <IconPeople/>
                        <span>{{ table.count_place }}</span>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            </transition>
          </div>
        </transition-group>
      </div>
    </main>
    <v-dialog class="popup-dialog" :width="500" />
    <modal name="support" :width="500" :height="'auto'" :classes="'popup'">
      <div class="popup__close" @click="$modal.hide('support')"></div>
      <div class="popup__title">Тех. поддержка</div>
      <div class="popup__contacts">
        <a href="mailto:support@antonov.group" class="popup__item">
          <div class="popup__img"><IconEmailTheme /></div>
          <div class="popup__info">
            Email:
            <span>support@antonov.group</span>
          </div>
        </a>
        <a href="tel:+79280147272" class="popup__item">
          <div class="popup__img"><IconPhoneTheme /></div>
          <div class="popup__info">
            Телефон:
            <span>+7 (928) 014-72-72</span>
          </div>
        </a>
        <a href="https://t.me/antonov_group" target="_blank" class="popup__item">
          <div class="popup__img"><IconTelegramTheme /></div>
          <div class="popup__info">
            Telegram:
            <span>@antonov_group</span>
          </div>
        </a>
        <div class="popup__item">
          <div class="popup__img"><IconMoney /></div>
          <div class="popup__info">
            Баланс СМС:
            <span>{{ tables.balance_sms }} руб. <IconWarning v-if="tables.balance_sms < 200" style="margin-bottom: -2px;" /></span>
          </div>
        </div>
        <button class="popup__exit" type="button" @click="logout">Выход</button>
      </div>
      <div class="popup__version">
        Система управления бронированием Artest v1.7.6
      </div>
      <a href="https://antonov.group/" target="_blank" class="popup__logo">
        <IconAGWhite />
      </a>
    </modal>
    <modal name="keeper" :width="500" :height="'auto'" :classes="'popup'">
      <div class="popup__close" @click="$modal.hide('keeper')"></div>
      <div class="popup__title">Информация о заказе</div>
      <div class="popup__order">
          <div class="popup__info">
            Номер заказа:
            <span>{{ keeper_info.name }}</span>
          </div>
          <div class="popup__info">
            Официант:
            <span>{{ keeper_info.waiter }}</span>
          </div>
          <div class="popup__info">
            Номер стола:
            <span>{{ keeper_info.table }}</span>
          </div>
          <div class="popup__info">
            Сумма предоплаты:
            <span>{{ keeper_info.prepay_sum }} &#8381;</span>
          </div>
      </div>
    </modal>
  </div>
</template>

<script>
import IconSix from '@/assets/img/table6.svg'
import IconSixRound from '@/assets/img/table6-1.svg'
import IconTwoRight from '@/assets/img/table2-2.svg'
import IconTwoLeft from '@/assets/img/table2-1.svg'
import IconTwo from '@/assets/img/table2.svg'
import IconTwoRotate from '@/assets/img/table2-rotate.svg'
import IconFourCross from '@/assets/img/table4.svg'
import IconFour from '@/assets/img/table4-1.svg'
import IconPeople from '@/assets/img/users.svg'
import IconSearch from '@/assets/img/search-icon.svg'
import IconClose from '@/assets/img/close-icon.svg'
import IconBack from '@/assets/img/back-icon.svg'
import IconEmailTheme from '@/assets/img/mail-theme.svg'
import IconPhoneTheme from '@/assets/img/phone-theme.svg'
import IconTelegramTheme from '@/assets/img/telegram-theme.svg'
import IconAGWhite from '@/assets/img/ag-white-logo.svg'
import IconMoney from '@/assets/img/money.svg'
import IconWarning from '@/assets/img/emojione_warning.svg'
import IconVeranda from '@/assets/img/veranda.svg'
import IconFireplace from '@/assets/img/fireplace.svg'
import IconTwoVeranda from '@/assets/img/table-2-v.svg'
import IconFourVeranda from '@/assets/img/table-4-v.svg'
import IconSixVeranda from '@/assets/img/table-6-v.svg'
import CarbonSearch from '@/assets/img/carbon_search.svg'
import Preloader from '@/assets/img/preloader.svg'
import Check from '@/assets/img/check-icon.svg'
import Cross from '@/assets/img/cross-icon.svg'
import Keeper from '@/assets/img/keeper-logo.svg'
import ChefIcon from '@/assets/img/chef.svg'
import ChefRotate from '@/assets/img/chef-1.svg'
import ChefNormal from '@/assets/img/chef-2.svg'
import ChefRotateReverse from '@/assets/img/chef-3.svg'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import sound from '@/assets/Sound_11330.wav'
import MaskedInput from 'vue-masked-input'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { debounce } from 'lodash'
import http from '@/http'
import { isToday } from 'date-fns'

export default {
  name: 'Booking',
  mixins: [validationMixin],
  components: {
    VueCtkDateTimePicker,
    MaskedInput,
    IconSix,
    IconSixRound,
    IconTwoRight,
    IconFour,
    IconTwoLeft,
    IconTwo,
    IconFourCross,
    IconPeople,
    IconSearch,
    IconClose,
    IconBack,
    IconEmailTheme,
    IconPhoneTheme,
    IconTelegramTheme,
    IconAGWhite,
    IconMoney,
    IconWarning,
    IconVeranda,
    IconFireplace,
    CarbonSearch,
    IconTwoRotate,
    IconTwoVeranda,
    IconFourVeranda,
    IconSixVeranda,
    Preloader,
    Check,
    Cross,
    Keeper,
    ChefIcon,
    ChefRotate,
    ChefNormal,
    ChefRotateReverse
  },
  validations () {
    return {
      reservation_form: {
        count_guests: { required },
        phone: { required },
        time_from: { required },
        time_to: { required },
        name: { required },
        email: { email }
      }
    }
  },
  data () {
    return {
      tables: {
        all_bookings: []
      },
      date_time: '',
      hall_filter: 3,
      overlay_visible: false,
      reservation_filter: 'active',
      sidebar_search: '',
      reservation_tab: 'table',
      selected_table: null,
      selected_table_data: {},
      reservation_form_visible: false,
      transfer_form_visible: false,
      reservation_form_sms_visible: true,
      reservation_form: {
        time_from: '',
        time_to: '',
        send_sms: false
      },
      transfer_form: {
        time_from: '',
        time_to: ''
      },
      selected_booking: null,
      booking_current: {},
      form_filled: false,
      reservation_form_readonly: false,
      min_date: '',
      timeout: null,
      stupid_timeout: null,
      filter: null,
      searchBookingsQuery: '',
      searchedBookings: [],
      sound: null,
      table_list: [],
      submit_wait: false,
      showStat: false,
      stat: [],
      stat_pay: {},
      keeper_info: {},
      stat_guests: {},
      keeper_wait: false,
      keeper_prepay_wait: false,
      current_hall: 1,
      current_tables: [],
      black_bg: false,
      halls: [
        {
          id: 'fireplace',
          icon: 'IconFireplace',
          number: 1
        },
        {
          id: 'chefTable',
          icon: 'ChefIcon',
          number: 2
        },
        {
          id: 'veranda',
          icon: 'IconVeranda',
          number: 3
        }
      ],
      no_set: false
    }
  },
  mounted () {
    this.sound = new Audio(sound)
    this.date_time = this.getCurrentDate()
    this.pollingData()
  },
  watch: {
    searchBookingsQuery (value) {
      if (value === '') {
        this.searchedBookings = []
      }
    },
    'reservation_form.time_from' (value) {
      if (!this.form_filled) {
        this.reservation_form.send_sms = value.substr(11, 13) >= '17'
      }
    }
  },
  computed: {
    sms_time () {
      return this.no_set ? '17' : '13'
    },
    hallsSwitchers () {
      return this.halls.filter(hall => hall.number !== this.current_hall)
    },
    filteredBookings () {
      return this.tables.all_bookings.filter(booking => {
        if (this.filter === null) {
          return true
        } else {
          return booking.status.indexOf(this.filter) > -1
        }
      })
    },
    filterCounter () {
      return {
        wait: this.tables.all_bookings.filter(booking => booking.status === 'wait').length,
        open: this.tables.all_bookings.filter(booking => booking.status === 'open').length,
        new: this.tables.all_bookings.filter(booking => booking.status === 'new').length
      }
    },
    inHallGuestWidth () {
      return this.stat_guests.in_hall / this.stat_guests.all * 100
    },
    waitGuestWidth () {
      return this.stat_guests.wait / this.stat_guests.all * 100
    },
    doneGuestWidth () {
      return this.stat_guests.done / this.stat_guests.all * 100
    }
  },
  methods: {
    onSearch (event) {
      this.searchBookings(event.target.value, this)
    },
    searchBookings: debounce((search, vm) => {
      http.search(search)
        .then(response => {
          vm.searchedBookings = response.data
        })
    }, 300),
    pollingData () {
      this.date_time = this.getCurrentDate()
      this.getStatGuests()
      this.getAllData(this.date_time)
        .then(response => {
          const bookingIsRead = response.all_bookings.filter(booking => booking.is_read === false)
          if (bookingIsRead.length) {
            this.sound.play()
            this.sound.currentTime = 0
          }
          this.timeout = setTimeout(() => {
            this.pollingData()
          }, 300000)
          if (this.selected_table_data && this.selected_table_data.id) {
            this.selected_table_data = this.tables.tables.find(table => table.id === this.selected_table_data.id)
          }
          if (this.booking_current && this.booking_current.id) {
            this.booking_current = this.tables.all_bookings.find(booking => booking.id === this.booking_current.id)
          }
        })
    },
    fetchStat () {
      http.getStat()
        .then(response => {
          this.stat = Object.entries(response.data)
        })
        .catch(error => {
          console.error(error.response)
        })
    },
    fetchStatPay () {
      http.getStatPay()
        .then(response => {
          this.stat_pay = response.data
        })
        .catch(error => {
          console.error(error.response)
        })
    },
    // diffArrays (a1, a2) {
    //   return a1.filter(i => !a2.includes(i)).concat(a2.filter(i => !a1.includes(i)))
    // },
    getAllData (dateTime) {
      return new Promise(resolve => {
        http.getAllData(dateTime)
          .then(response => {
            this.black_bg = false
            this.tables = response.data
            this.current_tables = this.tables.tables.filter(item => item.hall === this.current_hall)
            resolve(response.data)
          })
          .catch(error => {
            console.error(error.response)
          })
      })
    },
    onInputPicker () {
      if (this.date_time !== this.getCurrentDate()) {
        clearTimeout(this.timeout)
        this.timeout = null
        this.getAllData(this.date_time)
        this.getStatGuests()
      } else {
        if (!this.timeout) this.pollingData()
      }
      this.searchBookingsQuery = ''
    },
    onOverlayHide () {
      this.selected_table = null
      this.selected_booking = null
      this.overlay_visible = false
      this.reservation_tab = 'table'
      this.reservation_form_visible = false
      this.reservation_form_readonly = false
      this.reservation_form_sms_visible = true
      this.reservation_form = {
        time_from: '',
        time_to: ''
      }
      this.$v.$reset()
    },
    onTableClick (table) {
      if (this.selected_table) {
        this.selected_table = null
        this.overlay_visible = false
        this.selected_table_data = {}
        this.reservation_tab = 'table'
        this.reservation_form_visible = false
        this.reservation_form_readonly = false
        this.reservation_form_sms_visible = true
        this.reservation_form = {
          time_from: '',
          time_to: ''
        }
        this.$v.$reset()
      } else {
        this.overlay_visible = true
        this.selected_table = table.id
        this.selected_table_data = table
      }
    },
    onRemoveTable () {
      this.selected_table = null
      this.selected_booking = null
      this.overlay_visible = false
      this.selected_table_data = {}
      this.reservation_tab = 'table'
      this.reservation_form_visible = false
      this.transfer_form_visible = false
      this.reservation_form_readonly = false
      this.reservation_form_sms_visible = true
      this.form_filled = false
      this.reservation_form = {
        time_from: '',
        time_to: ''
      }
      this.$v.$reset()
      this.date_time = this.getCurrentDate()
      this.onInputPicker()
    },
    onOpenForm () {
      this.reservation_form_visible = true
    },
    onSubmitReservation (table) {
      this.$v.reservation_form.$touch()
      if (this.$v.reservation_form.$invalid) {
        this.$notify({
          type: 'warn',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.submit_wait = true
        const data = {
          ...this.reservation_form,
          time_from: this.convertDateTime(this.reservation_form.time_from),
          time_to: this.convertDateTime(this.reservation_form.time_to),
          is_read: true,
          table
        }
        if (!data.comment) {
          data.comment = ''
        }
        if (this.no_set) {
          data.comment = `${data.comment}#NO_SET#`
        }
        http.postReservationForm(data)
          .then(response => {
            this.submit_wait = false
            this.reservation_form_visible = false
            this.reservation_form_readonly = false
            this.reservation_form_sms_visible = true
            this.form_filled = false
            this.no_set = false
            this.$v.$reset()
            this.$notify({
              type: 'success',
              text: 'Заявка создана.'
            })
            this.reservation_form = {
              time_from: '',
              time_to: '',
              send_sms: false
            }
            this.getAllData(this.date_time)
              .then(() => {
                const data = this.tables.tables.filter(table => table.id === this.selected_table_data.id)
                this.selected_table_data = data[0]
              })
            if (this.form_filled) {
              http.patchBooking({
                id: response.data.id,
                status: 'open'
              })
                .then(() => {
                  this.getAllData(this.date_time)
                    .then(() => {
                      const data = this.tables.tables.filter(table => table.id === this.selected_table_data.id)
                      this.selected_table_data = data[0]
                      this.form_filled = false
                    })
                })
            }
          })
          .catch(error => {
            this.submit_wait = false
            this.$notify({
              type: 'error',
              text: error.response.data.detail
            })
          })
      }
    },
    onUpdateReservation (table) {
      this.$v.reservation_form.$touch()
      if (this.$v.reservation_form.$invalid) {
        this.$notify({
          type: 'warn',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.submit_wait = true
        this.reservation_form.send_sms = false
        const data = {
          ...this.reservation_form,
          client: this.reservation_form.client.id,
          table,
          time_from: this.convertDateTime(this.reservation_form.time_from),
          time_to: this.convertDateTime(this.reservation_form.time_to)
        }
        const today = isToday(new Date(this.reservation_form.time_from))
        http.patchReservationForm(data)
          .then(() => {
            this.submit_wait = false
            this.getAllData(this.date_time)
              .then(() => {
                if (today) {
                  this.selected_table_data = this.tables.tables.find(table => table.id === this.selected_table_data.id)
                  this.booking_current = this.tables.all_bookings.find(booking => booking.id === this.booking_current.id)
                  this.reservation_form_visible = false
                  this.reservation_form_readonly = false
                  this.reservation_form_sms_visible = true
                  this.$v.$reset()
                } else {
                  this.onRemoveTable()
                }
              })
              .catch(error => {
                this.$notify({
                  type: 'error',
                  text: error.response.data.detail
                })
              })
          })
          .catch(error => {
            this.submit_wait = false
            this.$notify({
              type: 'error',
              text: error.response.data.detail
            })
          })
      }
    },
    onUpdateTransfer () {
      let data
      console.log(this.table_list)
      if (this.transfer_form.change_date) {
        data = {
          id: this.transfer_form.id,
          table: this.table_list.find(table => table.number === this.transfer_form.table).id,
          time_from: this.convertDateTime(this.transfer_form.time_from),
          time_to: this.convertDateTime(this.transfer_form.time_to)
        }
      } else {
        data = {
          id: this.transfer_form.id,
          table: this.table_list.find(table => table.number === this.transfer_form.table).id
        }
      }
      this.submit_wait = true
      console.log(data)
      http.patchReservationForm(data)
        .then(() => {
          this.submit_wait = false
          this.getAllData(this.date_time)
            .then(() => {
              const data = this.tables.tables.filter(table => table.id === this.selected_table_data.id)
              const bookingData = this.tables.all_bookings.filter(booking => booking.id === this.booking_current.id)
              this.transfer_form.table = ''
              this.transfer_form_visible = false
              this.selected_table_data = data[0]
              this.booking_current = bookingData[0]
              this.$v.$reset()
            })
        })
        .catch(error => {
          this.submit_wait = false
          this.$notify({
            type: 'error',
            text: error.response.data.detail
          })
        })
    },
    onFillForm () {
      const date = new Date()
      const dtISO = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString()
      this.reservation_form.time_from = dtISO.slice(0, 16)
      const dateTo = new Date(date.setHours(date.getHours() + 2))
      this.reservation_form.time_to = new Date(dateTo.getTime() - (dateTo.getTimezoneOffset() * 60000)).toISOString().slice(0, 16)
      this.reservation_form.phone = '+79000000000'
      this.reservation_form.name = 'Гость'
      this.form_filled = true
    },
    onBackFromForm () {
      this.reservation_form_visible = false
      this.transfer_form_visible = false
      this.reservation_form_readonly = false
      this.reservation_form_sms_visible = true
      this.$v.$reset()
    },
    onReservationCardClick (cardId, index, card) {
      this.date_time = card.time_from
      this.onInputPicker()
      this.selected_booking = cardId
      this.booking_current = card
      const table = this.tables.tables.filter(table => table.id === card.table)
      this.selected_table_data = table[0]
      this.overlay_visible = true
      this.selected_table = false
    },
    onReservationCardListClick (cardId, card) {
      this.date_time = card.time_from
      this.onInputPicker()
      this.selected_booking = cardId
      this.booking_current = card
      const table = this.tables.tables.filter(table => table.id === card.table)
      this.current_hall = table[0].hall
      this.selected_table_data = table[0]
      this.overlay_visible = true
      this.reservation_form.send_sms = false
    },
    onInputDate () {
      if (!this.date_time) this.getAllData(this.date_time)
    },
    onPopupOpen (bookingId, status) {
      let pActionWord = 'открыть'

      if (status === 'cancel') {
        pActionWord = 'отменить'
      } else if (status === 'close') {
        pActionWord = 'зыкрыть'
      } else {
        pActionWord = 'открыть'
      }

      this.$modal.show(
        'dialog',
        {
          title: 'Подтверждение',
          text: `Вы уверены, что хотите ${pActionWord} данное бронирование?`,
          buttons: [
            {
              title: 'Подтвердить действие',
              handler: () => {
                this.onPatchBooking(bookingId, status)
                this.$modal.hide('dialog')
              }
            },
            {
              class: 'close-button',
              handler: () => {
                this.$modal.hide('dialog')
              }
            }
          ]
        }
      )
    },
    onPatchBooking (bookingId, status) {
      const data = {
        id: bookingId,
        status
      }
      if (status === 'close' || status === 'cancel') {
        this.selected_table = null
        this.selected_booking = null
        this.overlay_visible = false
      }
      if (status === 'open') {
        data.time_from = new Date().toLocaleString().replace(',', '').slice(0, 16)
        data.time_to = this.booking_current.time_to
      }
      http.patchBooking(data)
        .then(() => {
          this.getAllData(this.date_time)
            .then(() => {
              if (status !== 'close' || status !== 'cancel') {
                const data = this.tables.tables.filter(table => table.id === this.selected_table_data.id)
                this.selected_table_data = data[0]
                const bookingData = this.tables.all_bookings.filter(booking => booking.id === this.booking_current.id)
                this.booking_current = bookingData[0]
              }
              this.$notify({
                type: 'success',
                text: 'Статус заявки изменен.'
              })
            })
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            text: error.response.data.detail
          })
        })
    },
    onReservationUpdate (id) {
      http.getBooking(id)
        .then(response => {
          const fromDate = this.convertDateToArray(response.data.time_from)
          const toDate = this.convertDateToArray(response.data.time_to)
          this.reservation_form = response.data
          const dateFrom = new Date(fromDate[2], fromDate[1] - 1, fromDate[0], fromDate[3], fromDate[4])
          const dateTo = new Date(toDate[2], toDate[1] - 1, toDate[0], toDate[3], toDate[4])
          this.reservation_form.time_from = new Date(dateFrom.getTime() - (dateFrom.getTimezoneOffset() * 60000)).toISOString().slice(0, 16)
          this.reservation_form.time_to = new Date(dateTo.getTime() - (dateTo.getTimezoneOffset() * 60000)).toISOString().slice(0, 16)
          this.reservation_form.name = response.data.client.name
          this.reservation_form.phone = response.data.client.phone
          this.reservation_form.email = response.data.client.email
          this.reservation_form.comment = response.data.comment
          this.reservation_form_visible = true
          this.reservation_form_readonly = true
          this.reservation_form_sms_visible = false
        })
    },
    onBookingContinue () {
      const date = new Date()
      const dateContinue = new Date(date.setMinutes(date.getMinutes() + 15)).toLocaleString().replace(',', '').slice(0, 16)
      http.patchBooking({
        id: this.booking_current.id,
        status: 'new',
        time_from: dateContinue
      })
        .then(() => {
          this.getAllData(this.date_time)
            .then(() => {
              const data = this.tables.tables.filter(table => table.id === this.selected_table_data.id)
              this.selected_table_data = data[0]
              const bookingData = this.tables.all_bookings.filter(booking => booking.id === this.booking_current.id)
              this.booking_current = bookingData[0]
              this.$notify({
                type: 'success',
                text: 'Время заявки сдвинуто на 15 минут.'
              })
            })
        })
    },
    onFormatPicker () {
      const date = new Date()
      this.min_date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(0, 16).replace('T', ' ')
    },
    onFilterSelect (event) {
      if (event.target.value === this.filter) {
        this.filter = null
      }
    },
    convertDateTime (string) {
      const dateTime = string.split('T')
      const date = new Date(dateTime[0])
      return `${date.toLocaleDateString('ru-RU')} ${dateTime[1]}`.slice(0, 16)
    },
    convertDateToArray (date) {
      const dateTimeArray = date.split(' ')
      const dateArray = dateTimeArray[0].split('.')
      const timeArray = dateTimeArray[1].split(':')
      return [...dateArray, ...timeArray]
    },
    getCurrentDate () {
      const date = new Date()
      const dtISO = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString()
      return this.convertDateTime(dtISO.slice(0, 16))
    },
    plusTheeHours () {
      const date = new Date(this.reservation_form.time_from)

      if (date.getHours() < 21 || (date.getHours() === 21 && date.getMinutes() <= 30)) {
        const dateTo = new Date(date.setHours(date.getHours() + 2))
        this.reservation_form.time_to = new Date(dateTo.getTime() - (dateTo.getTimezoneOffset() * 60000)).toISOString().slice(0, 16)
      } else {
        let dateTo = new Date(date.setHours(23))
        dateTo = new Date(date.setMinutes(30))
        this.reservation_form.time_to = new Date(dateTo.getTime() - (dateTo.getTimezoneOffset() * 60000)).toISOString().slice(0, 16)
      }

      // if (date.getHours() < 19) {
      //   const dateTo = new Date(date.setHours(date.getHours() + 2))
      //   this.reservation_form.time_to = new Date(dateTo.getTime() - (dateTo.getTimezoneOffset() * 60000)).toISOString().slice(0, 16)
      // } else if (date.getHours() >= 19) {
      //   if (date.getMinutes() < 30) {
      //     const dateTo = new Date(date.setHours(date.getHours() + 2))
      //     this.reservation_form.time_to = new Date(dateTo.getTime() - (dateTo.getTimezoneOffset() * 60000)).toISOString().slice(0, 16)
      //   } else if (date.getMinutes() >= 30) {
      //     let dateTo = new Date(date.setHours(23))
      //     dateTo = new Date(date.setMinutes(30))
      //     this.reservation_form.time_to = new Date(dateTo.getTime() - (dateTo.getTimezoneOffset() * 60000)).toISOString().slice(0, 16)
      //   }
      // }
    },
    onBookingIsRead (id) {
      http.patchBooking({
        id,
        is_read: true
      })
        .then(() => {
          this.getAllData(this.date_time)
            .then(() => {
              const bookingData = this.tables.all_bookings.filter(booking => booking.id === this.booking_current.id)
              this.booking_current = bookingData[0]
            })
        })
    },
    onBookingTransfer (id) {
      http.getBooking(id)
        .then(response => {
          const fromDate = this.convertDateToArray(response.data.time_from)
          const toDate = this.convertDateToArray(response.data.time_to)
          const dateFrom = new Date(fromDate[2], fromDate[1] - 1, fromDate[0], fromDate[3], fromDate[4])
          const dateTo = new Date(toDate[2], toDate[1] - 1, toDate[0], toDate[3], toDate[4])
          this.transfer_form.time_from = new Date(dateFrom.getTime() - (dateFrom.getTimezoneOffset() * 60000)).toISOString().slice(0, 16)
          this.transfer_form.time_to = new Date(dateTo.getTime() - (dateTo.getTimezoneOffset() * 60000)).toISOString().slice(0, 16)
          this.transfer_form.change_date = false
          this.transfer_form.name = response.data.client.name
          this.transfer_form.phone = response.data.client.phone
          this.transfer_form.comment = response.data.client.comment
          this.transfer_form.id = response.data.id
          this.transfer_form_visible = true
          http.getTables()
            .then(response => {
              this.table_list = response.data.results
            })
        })
    },
    logout () {
      http.logout()
        .then(() => {
          this.$emit('is-auth')
        })
    },
    onSwitchContent () {
      this.showStat = !this.showStat
      if (this.showStat) {
        this.fetchStat()
        this.fetchStatPay()
      }
    },
    onStatClick (date) {
      this.showStat = !this.showStat
      this.date_time = `${date} 09:00`
      this.onInputPicker()
    },
    onSMSResend (bookingId) {
      http.reSendSMS(bookingId)
        .then(() => {
          this.$notify({
            type: 'success',
            text: 'SMS успешно отправлено.'
          })
        })
        .catch(error => {
          this.$notify({
            type: 'error',
            text: error.response.data.detail
          })
        })
    },
    onKeeperPopup (bookingId) {
      http.getKeeperInfo(bookingId)
        .then(response => {
          this.keeper_info = response.data
          this.$modal.show('keeper')
        })
        .catch(error => {
          this.$notify({
            type: 'error',
            text: error.response.data.detail
          })
        })
    },
    onKeeperBook (bookingId) {
      this.keeper_wait = true
      http.postKeeperBook(bookingId)
        .then(() => {
          this.keeper_wait = false
          this.$notify({
            type: 'success',
            text: 'Бронь в r-keeper успешно создана.'
          })
          this.getAllData(this.date_time)
            .then(() => {
              const bookingData = this.tables.all_bookings.filter(booking => booking.id === bookingId)
              this.booking_current = bookingData[0]
            })
        })
        .catch(() => {
          this.keeper_wait = false
          this.$notify({
            type: 'error',
            text: 'Ошибка создания брони в r-keeper.'
          })
        })
    },
    onKeeperPrepay (bookingId) {
      this.keeper_prepay_wait = true
      http.postKeeperPrepay(bookingId)
        .then(() => {
          this.keeper_prepay_wait = false
          this.$notify({
            type: 'success',
            text: 'Предоплата в r-keeper успешно внесена.'
          })
          this.getAllData(this.date_time)
            .then(() => {
              const bookingData = this.tables.all_bookings.filter(booking => booking.id === bookingId)
              this.booking_current = bookingData[0]
            })
        })
        .catch(() => {
          this.keeper_prepay_wait = false
          this.$notify({
            type: 'error',
            text: 'Ошибка внесения предоплаты в r-keeper.'
          })
        })
    },
    getStatGuests () {
      http.getStatGuest(this.date_time.slice(0, 10))
        .then(response => {
          this.stat_guests = response.data
        })
        .catch(error => {
          this.$notify({
            type: 'error',
            text: error.response.data.detail
          })
        })
    },
    exportExcel () {
      http.exportStat()
        .then(response => {
          const url = URL.createObjectURL(response.data)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Статистика Оплат ARTEST.csv')
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(error => {
          this.$notify({
            type: 'error',
            text: error.response.data.detail
          })
        })
    },
    onChangeHall (hall) {
      this.black_bg = true
      this.current_hall = hall
      this.getAllData(this.date_time)
    },
    onAddToCalendar (bookingId) {
      http.addToCalendar(bookingId)
        .then(() => {
          this.$notify({
            type: 'success',
            text: 'Бронь добавлена в календарь шефа'
          })
          this.getAllData(this.date_time)
            .then(() => {
              this.booking_current = this.tables.all_bookings.find(booking => booking.id === this.booking_current.id)
            })
        })
        .catch(error => {
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
    onRemoveFromCalendar (bookingId) {
      http.removeFromCalendar(bookingId)
        .then(() => {
          this.$notify({
            type: 'success',
            text: 'Бронь удалена из календаря шефа'
          })
          this.getAllData(this.date_time)
            .then(() => {
              this.booking_current = this.tables.all_bookings.find(booking => booking.id === this.booking_current.id)
            })
        })
        .catch(error => {
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key][0]
              })
            }
          }
        })
    }
  }
}
</script>

<style lang="sass">
.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
