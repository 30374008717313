<template>
  <div class="auth">
    <div class="auth__body">
      <a href="#" target="_blank" class="auth__logo auth__logo--top">
        <IconATLogo />
      </a>
      <div class="auth__title">Авторизация</div>
      <form class="auth__form" @submit.prevent="onSubmitForm">
        <div class="auth__control">
          <label for="username" class="auth__label">Логин</label>
          <input
            v-model="form.username"
            id="username" class="auth__input"
            type="text" name="name"
            placeholder="Введите имя пользователя"
          >
        </div>
        <div class="auth__control">
          <label for="password" class="auth__label">Пароль</label>
          <input
            v-model="form.password"
            id="password" class="auth__input"
            type="text"
            name="password"
            placeholder="Введите пароль"
          >
        </div>
        <button type="submit" class="auth__submit">Войти</button>
      </form>
      <a href="#" target="_blank" class="auth__logo">
        <IconAGLogo />
      </a>
    </div>
  </div>
</template>

<script>
import http from '@/http'
import IconATLogo from '@/assets/img/at-logo.svg'
import IconAGLogo from '@/assets/img/ag-logo.svg'

export default {
  name: 'Auth',
  components: {
    IconATLogo,
    IconAGLogo
  },
  data () {
    return {
      form: {}
    }
  },
  methods: {
    onSubmitForm () {
      http.login(this.form)
        .then(() => {
          this.$emit('is-auth')
        })
        .catch(error => {
          this.$notify({
            type: 'error',
            text: `${error.response.data.detail}`
          })
        })
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/common/index.scss"

.auth
  display: flex
  align-items: center
  justify-content: center
  width: 100%

.auth__body
  width: 700px
  min-height: 600px
  padding: 40px 20px
  background-color: $color-bg-second
  text-align: center

.auth__logo--top
  margin-bottom: 30px

.auth__logo
  display: inline-block
  line-height: 0

.auth__title
  margin-bottom: 38px
  color: #fff
  font-size: 24px
  font-weight: 600
  text-transform: uppercase

.auth__form
  width: 380px
  margin: 0 auto 52px

.auth__control
  margin-bottom: 20px

  &:last-of-type
    margin-bottom: 0

.auth__label
  display: block
  margin-bottom: 8px
  color: #fff
  font-size: 14px
  text-align: left

.auth__input
  width: 100%
  padding: 13px 10px
  border: 1px solid $color-border
  border-radius: 5px
  color: #fff
  font-size: 14px
  background-color: transparent

  &::placeholder
    font-family: $font-family-base
    color: $color-border
    font-size: 14px

.auth__submit
  width: 280px
  margin: 30px auto 0
  padding: 16px 0
  border-radius: 5px
  color: #fff
  font-size: 14px
  text-align: center
  background-color: #27ae60

</style>
