<template>
  <div id="app" :style="{ 'height': viewportHeight + 'px' }">
    <Auth v-if="!isAuth" @is-auth="isAuth = true" />
    <Booking v-else  @is-auth="isAuth = false" />
    <notifications classes="my-notify" width="500" :duration="8000" />
  </div>
</template>

<script>
import Auth from './views/Auth'
import Booking from './views/Booking'
import { isLoggedIn } from 'axios-jwt'

export default {
  name: 'App',
  components: {
    Auth,
    Booking
  },
  data () {
    return {
      viewportHeight: 700
    }
  },
  computed: {
    isAuth () {
      return isLoggedIn()
    }
  },
  async created () {
    this.viewportHeight = window.innerHeight
  }
}
</script>
<style lang="sass" scoped>
#app
  display: flex
  flex-grow: 1
</style>
